import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faFlagCheckered, faPeopleGroup, faXmark} from '@fortawesome/free-solid-svg-icons';

export default function TeamCard(props) {
	const team = props.team;
	const stats = team.stats;
	const numUsers = stats.users || 0;
	const correctAnswers = stats.correctAnswers || 0;
	const totalAnswers = stats.totalAnswers || 0;
	const totalScores = stats.totalScores || 0;
	
	return (<div className={'team-card'} style={{backgroundColor: team.color}}>
			<div className={'team-name'}>{team.name}</div>
			<div className={'team-card__img'} style={team.img ? {backgroundImage: `url("${team.img.src}")`} : null}></div>
			<div className={'team-card__content'}>
				<div className={'team-card__content_col_1'}>
					<TeamCardBar color={team.color} percent={stats.percent}/>
				</div>
				<div className={'team-card__content_col_2'}>
					<TeamStatsRow icon={faPeopleGroup} label={'Players'} value={numUsers} className={'players'}/>
					<TeamStatsRow icon={faCheck} label={'Correct Answers'} value={correctAnswers} className={'correct'}/>
					<TeamStatsRow icon={faXmark} label={'Incorrect Answers'} value={totalAnswers - correctAnswers} className={'incorrect'}/>
					<TeamStatsRow icon={faFlagCheckered} label={'Total Scores'} value={totalScores} className={'total'}/>
				</div>
			</div>
		</div>
	);
}

function TeamStatsRow(props) {
	return (
		<div className={'team-card__content_stats'}>
			<div className={'team-card__content_stats__icon'}>
				<FontAwesomeIcon icon={props.icon}/>
			</div>
			<div className={'team-card__content_stats__label'}>
				{props.label}
			</div>
			<div className={'team-card__content_stats__value'}>{props.value}</div>
		</div>
	)
}

function TeamCardBar({color, percent}) {
	const [colHeight, setColHeight] = useState(0);
	const [displayPercent, setDisplayPercent] = useState(0);
	const lastVisiblePercent = useRef(0);
	
	/**
	 * Animated number update.
	 */
	useEffect(() => {
		const dt = Math.abs(percent - lastVisiblePercent.current) || 1;
		const time = 1000 / dt;
		const percentInt = setInterval(() => {
			setDisplayPercent(prev => {
				if (percent > prev) {
					return prev + 1;
				} else if (percent < prev) {
					return prev - 1;
				}
				clearInterval(percentInt);
				
				return prev;
			});
		}, time);
		
		setColHeight(percent);
		
		return () => clearInterval(percentInt);
	}, [percent]);
	
	useEffect(() => {
		lastVisiblePercent.current = displayPercent;
	}, [displayPercent]);
	
	return (
		<div className={'team-card-bar'}>
			<div className={'team-card-bar__inner'} style={{borderColor: color}}>
				<div className={'team-card-bar__col'} style={{backgroundColor: color, height: `${colHeight}%`}}></div>
				<div className={'team-card-bar__text'}>{displayPercent}%</div>
			</div>
		</div>
	)
}
